
/**
 * @description Configuration
 */
export const configurationOptions = {
  initialState: {
    sortField: 'core:text',
    sortDirection: 'asc',
    resultsPerPage: 20,
    current: 1,
  },
  searchQuery: {
    search_fields: {
      title: {},
      topics: {},
      locations: {},
      industries: {}
    },
    result_fields: {
      title: {
        snippet: {
          size: 75,
          fallback: true
        }
      },
      url: {
        snippet: {
          fallback: true
        }
      },
      image_url: {
        raw: {},
        snippet: {
          fallback: true
        }
      },
      published_by: {
        snippet: {
          size: 50,
          fallback: true
        }
      },
      author_link: {
        snippet: {
          size: 50,
          fallback: true
        }
      },
      topics: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      type: {
        snippet: {
          size: 50,
          fallback: true
        }
      },
      published_on: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      locations: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      },
      industries: {
        raw: {},
        snippet: {
          size: 50,
          fallback: true
        }
      }
    },
    facets: {
      // importance: {
      //   type: 'value', size: 100
      // },
      // topics: {
      //   type: 'value', size: 100
      // },
      // published_by: {
      //   type: 'value', size: 100
      // },
      // locations: {
      //   type: 'value', size: 100
      // },
      // industry: {
      //   type: 'value', size: 100
      // },
      // practice_groups: {
      //   type: 'value', size: 100
      // },
      // published_on: {
      //   type: 'range',
      //   ranges: [
      //     { from: startDay, to: endDay, name: 'Today' },
      //     { from: yestStartDay, to: yestEndDay, name: 'Yesterday' },
      //     { from: lastWeekStartDay, to: lastWeekEndDay, name: 'Last Week' },
      //     { from: lastMonthStartDay, to: lastMonthEndDay, name: 'Last Month' },
      //     { from: startQuarterDay, to: endQuarterDay, name: 'Last Quarter' },
      //     { from: lastSixMonthStart, to: lastSixMonthEnd, name: 'Last 6 Months' },
      //     { from: initialStartOfYear, to: endDay, name: 'All' }
      //   ]
      // }
    }

  },
  alwaysSearchOnInitialLoad: true
}
