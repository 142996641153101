
import React, { Component } from 'react'
import AddNeedsToSetTemplate from '../components/question/add-needs-to-set'
/**
 * @description AddNeedsToSet container component 
 */
class AddNeedsToSet extends Component {
    constructor (props) {
        super(props)
        this.state = {
            changeSetList: this.props.sidebarList,
        }
    }
    /**
     * @description Handle set search 
     */
    handleSetSearch = (e) => {
        e.preventDefault()
        const {sidebarList} = this.props
        const val = e.target.value
        const filterSets = sidebarList.filter((set) => {
            const title = set['dc:title'].toLowerCase()
            return title.includes(val.toLowerCase())
        })
        this.setState({...this.state, changeSetList:filterSets })
    }
    render () {
        const {saveNeedsToSet, handlePopup, selectedNeeds, handleHeaderPopup, needId, getInfoNeed} = this.props
        let needs = []
        if(needId) {
            needs.push(needId)
        } else {
            needs = []
        }
        return (
            <>
            <AddNeedsToSetTemplate 
            handlePopup={handlePopup} 
            handleSetSearch={this.handleSetSearch}
            sidebarList={this.state.changeSetList}
            saveNeedsToSet={saveNeedsToSet}
            selectedNeeds={needs.length > 0 ? needs : selectedNeeds}
            handleHeaderPopup={handleHeaderPopup}
            needId={needId}
            getInfoNeed={getInfoNeed}
            />
            </> 

        )
    }
}
export default AddNeedsToSet
