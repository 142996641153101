import React from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useStyles } from './style'

/**
 * @description SignIn component for login page template
 * @param {func} loginSubmit
 * @param {boolean} error
 * @param {string} message
 */
export default function SignIn ({ loginSubmit, error, message }) {
  const classes = useStyles()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}> */}
        <img alt="img" src={require('../../..//assets/mind-alliance.png') } onError={(e) => {
                  // e.target.src = '../../../assets/no-image.png'
                  e.target.style = 'visibility: hidden;' // inline styles in html format
                }} width="50"></img>
          {/* <LockOutlinedIcon /> */}
        {/* </Avatar> */}
        <Typography component="h1" variant="h5">
          Sign in to Mind Alliance
        </Typography>
        <p style={{ color: 'red' }}>{error ? message : ''}</p>
        <form className={classes.form} onSubmit={loginSubmit}>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  )
}
/** SignIn component prop types */
SignIn.propTypes = {
  error: PropTypes.bool,
  loginSubmit: PropTypes.func,
  message: PropTypes.string
}
