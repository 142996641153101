
import React, { Component } from 'react'
import AddQuestionSetTemplate from '../components/question/question-set'
/**
 * @description AddInfoSet container component 
 */
class AddInfoSet extends Component {
    constructor (props) {
        super(props)
        this.state = {
            changeSetList: this.props.sidebarList
        }
    }
    /**
     * @description Handle set search 
     */
    handleSetSearch = (e) => {
        e.preventDefault()
        const {sidebarList} = this.props
        const val = e.target.value
        const filterSets = sidebarList.filter((set) => {
            const title = set['dc:title'].toLowerCase()
            return title.includes(val.toLowerCase())
        })
        this.setState({...this.state, changeSetList:filterSets })
    }
    render () {
        const {saveQuestionSet, handlePopup, handleHeaderPopup} = this.props
        return (
            <>
            <AddQuestionSetTemplate 
            handlePopup={handlePopup} 
            saveQuestionSet={saveQuestionSet}
            handleHeaderPopup={handleHeaderPopup}
            />
            </> 

        )
    }
}
export default AddInfoSet
