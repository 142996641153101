import CONSTANTS from '../../constants'
/**
 * @description filter metadata values
 */
export const filterMetaValue = (value) => {
    const filterValues =  value && value.length > 0 ? value.split(':') : value
    if(filterValues && filterValues.length > 0) {
      return filterValues[1]
    } 
    return filterValues
}
/**
 * @description set Meta value
 */
export const setMetaValue = (value) => {
    return 'in:'+value
}
export const getSubValue = (value) => {
  const filterValues =  value && value.length > 0 ? value.split(':') : value
  if(filterValues && filterValues.length > 0) {
    return filterValues[0]
  } 
  return filterValues
}
/**
 * @description set meta value with sub value
 * @param {*} str
 */ 
export const setSubMetaValue = (sub, value) => {
  if(sub && value) {
    return sub + ':' + value
  }
  return ''
  
}
/**
 * @description Check for base 64 encode/decode
 */
export const isBase64 = (str) => {
    try {
      return btoa(atob(str)) === str
    } catch (err) {
      return false
    }
  }
/**
 * @description Capitalize first letter of string
 * @param {*} s 
 */
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
/**
 * @description Get readable filter value
 * @param {*} val 
 */
export const getReadFacetValue = (val) => {
    val = val.trim()
    if(val.includes('_')) {
      return val.split("_").join(' ')
    }
    return val
}
/**
 * @description Set filter value as per request object
 * @param {*} val 
 */
export const setFacetValueReqObj = (val) => {
  return val.split(" ").join('_')
}
/**
 * @description Get meta codes
 */
export const getMetaCode = (val) => {
  let metaCodes = CONSTANTS.META_CODES
  let uVal = capitalize(val)
  return metaCodes[uVal] ? metaCodes[uVal] : ''
}