import React from "react"
import instance from "../config/axios-config"
import Spinner from '../components/spinner/spinner'
import SidebarMenu from '../components/sidebar-menu/sidebar-menu'
import  CONSTANTS  from '../../constants'
const { LIST_INFO_SET_REQ_OBJ, COUNT_OBJ_All_INFO } = CONSTANTS
/**
 * @desc Sidebar component to handle sidebar functionality
*/
class Sidebar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            error: false,
            message: "",
            loader: true,
            setsData: [],
            changeSets: [],
            infoTotalCount: 0,
        }
        this.loaderRef = React.createRef();
    }
     /** @desc When component will setup */
    componentDidMount () {
        this.loaderRef.current && this.loaderRef.current.showLoader()
        return instance.post('/search-sets', {searchObj: LIST_INFO_SET_REQ_OBJ, countObj: COUNT_OBJ_All_INFO})
       // return instance.post('/search-sets', {searchObj: LIST_INFO_SET_REQ_OBJ})
        .then(res => {
            this.setState({
            setsData: res.data.data.items ? res.data.data.items : [],
            changeSets: res.data.data.items ? res.data.data.items : [],
            infoTotalCount: res.data.data.count ? res.data.data.count : 0,
            loadData: true,
            loader: false,
            error: false,
            })
            this.props.updateSidebarList(res.data.data.items)
        })
        .catch(() => {
            this.setState({
            error: true,
            loader: false,
            loadData: false
            })
        })
        .finally(() => { this.loaderRef.current && this.loaderRef.current.hideLoader()})
    }
    /**
     * @description Handle set search 
     */
    handleSetSearch = (e) => {
        e.preventDefault()
        const {setsData} = this.state
        const val = e.target.value
        const filterSets = setsData.filter((set) => {
            const title = set['dc:title'].toLowerCase()
            return title.includes(val.toLowerCase())
        })
        this.setState({...this.state, changeSets:filterSets })
    }
    render () {
        const { changeSets, infoTotalCount } = this.state
        const handleSearch = this.handleSetSearch
        return (
        <div>
            {/* {loader ? <Spinner /> : */}
                <div>
                    <Spinner ref={this.loaderRef}/>
                    <SidebarMenu infoTotalCount={infoTotalCount} setsData={changeSets} handleSetSearch={handleSearch}/>
                </div>
            {/* }  */}
        </div>
        )
    }
}
export default Sidebar