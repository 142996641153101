import React from 'react';
import Button from '@material-ui/core/Button';
import CONSTANTS from '../../../constants'


/**
 * @description upload info need template
 */
export default function UploadInfoNeedTemplate(data) {
    const {uploadQuestions, handlePopup, handleHeaderPopup} = data
    const [selectedFile, setFileValue] = React.useState(null)
    const onFileChange = (event) => { 
        // Update the state 
        setFileValue(event.target.files[0])
    }; 
    const onFileUpload = () => { 

        // Create an object of formData 
        const formData = new FormData(); 
        // Update the formData object 
        formData.append( 
            "csv", 
            selectedFile,
        ); 
        formData.append( 
            "spec", 
            JSON.stringify(CONSTANTS.CSV_SPEC)
        ); 
        return uploadQuestions(formData)
    }; 
    return (
        <>
            <div className="info-modal">
                <div className="info-modal-bodyContent">       
                    <div className="common-modal-outer mT30">
                        <div className="upload_header">
                            <div className="upload_header_icon">
                                <span className="icon-cloud-upload"></span>
                            </div>
                            <div className="upload_header_content">
                                <h3>Add Info Needs to the Question Database<br/> by uploading  a <strong>.csv</strong> file from your computer.</h3> 
                                {
                                    selectedFile && selectedFile !== null ?  (
                                        <>
                                        <div className="keyword-outer cursor-set" onClick={(e) =>{e.preventDefault();setFileValue(null)}}>
                                        <i className="fa fa-file-excel-o" aria-hidden="true"></i><span>{selectedFile.name}</span>
                                            <span className="fa fa-times" ></span>
                                        </div>
                                        </>
                                    ) : 
                                        (
                                        <div className="upload-wrap">
                                            <Button type="button" className="nice-button cursor-set"><span className="icon-plus"></span>Select file from your computer</Button>
                                            <input type="file" accept=".csv" name="file" onChange={(event) => {onFileChange(event)}} className="upload-btn"/>
                                        </div>   
                                        )
                                }                                     
                            </div>
                        </div>
                        <div className="upload_bodyContent">
                            <div className="upload_bodyContent_title">CSV FORMAT GUIDELINES</div>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>

                            <div className="sample_download">
                                <span className="icon-cloud-download"></span> <strong>
                                    <a href="./data/sample.csv" download="sample.csv">Download Sample</a></strong>
                            </div>  
                        </div>
                        {/* <div><a href="./data/test1.csv" download="sample.csv">Download</a></div>    */}
                        <div className="modal-footer">
                            <button className="cancel_btn" onClick={() => {handlePopup('');handleHeaderPopup('')}}>CANCEL</button>
                            <button className="save_btn" disabled={!selectedFile} onClick={() => {onFileUpload()}}>
                                <span className="icon-cloud-upload"></span>UPLOAD</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}