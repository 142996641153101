import React, { Component } from 'react'
import {
    PagingInfo,
    Results,
    Sorting,
} from '@elastic/react-search-ui'
import AddNeedsToSet from '../../containers/info-needs-to-set';
import UploadInfoNeeds from '../../containers/upload-info-needs';
import Modal from '../UI/modal'
import AddInfoSet from '../../containers/info-set'
import InfoNeed from '../../containers/info-need'
import ResultCard from '../result/result'
/**
 * @description main search template for main view
 */
class MainSearchTemplate extends Component {
render () {
    const { 
        handleHeaderPopup, saveQuestionSet, props, saveNeedsToSet, handlePopup, setId, toggle, masterToggle, showWarning,
        setName, saveQuestion, getInfoNeed, snackRef, handleBulkDeleteBox, results, isLoading, searchTerm, loaderRef, setSearchTerm,
        reset, filters, removeFilter, selectedQuestions, totalResults, popupValue, needId, uploadQuestions } = this.props
    const { sidebarList } = props 
    return (
    <>
        <div className="main-Outer">
            <div className="topHeader">
                <div className="innerHeader">
                    <div className="leftHeader_item">
                    {selectedQuestions.length === 0 && (
                        <>
                            <div>
                            <span>{totalResults} Info Needs </span>
                            </div> 
                            </>
                            )}
                        <div className="outer_selection">
                        {selectedQuestions.length > 0 && (
                        <>
                            <div className="selected_item">
                            <span className="icon-minus" ></span> 
                            <span>{selectedQuestions.length} Info Needs Selected</span>
                            </div>
                            <div className="select_all_item" onClick={() => {masterToggle(results)}}>
                            {selectedQuestions.length === results.length ? 'Un-Select All' : 'Select All'}</div>
                            </>
                            )} 
                        </div>
                    </div>
                    {
                    selectedQuestions.length > 0 && (
                        <div className="rightHeader_item">
                            <div className="selected_moreOption">
                                    {/* <div><span className="icon-cloud-download"></span> Download</div> */}
                                    <div onClick={() => { 
                                    selectedQuestions.length > 0 ? handlePopup('addNeedsToSet') : showWarning("Please select any info need to add in set")
                                    }}><span className="icon-folder-alt"></span> Add to Info Need Set</div>
                                    <div 
                                    onClick={() => { 
                                    selectedQuestions.length > 0 ? handleBulkDeleteBox() : showWarning("Please select any info need to delete")
                                    }}><span className="icon-trash"></span> Delete</div>
                            </div>                                  
                        </div>
                    )   
                    }
                    
                </div>
            </div>
            
            <div className="bodyContainer">
            <div className="innerBody-sec">
            {/* <button onClick={() => { handlePopup('addNeedsToSet')}}>Add needs to set</button> */}
                {popupValue === 'addQuestionSet' && (  
                    <Modal show={true} handlePopup={handlePopup}>
                    <AddInfoSet saveQuestionSet={saveQuestionSet} handlePopup={handlePopup} handleHeaderPopup={handleHeaderPopup} />
                    </Modal>
                )}
                {popupValue === 'infoNeed' && (
                    <Modal show={true}  handlePopup={handlePopup}>
                        <InfoNeed  
                        handlePopup={handlePopup}
                        handleHeaderPopup={handleHeaderPopup} 
                        saveQuestion={saveQuestion}
                        type='add'
                        needId={needId}
                        setName={setName} 
                        setId={setId}
                        snackbarRef={snackRef}
                        toggle={toggle}
                        loaderRef={loaderRef}
                        />
                    </Modal>
                )}
                {popupValue === 'detailInfoNeed' && (
                    <Modal show={true}  handlePopup={handlePopup}>
                        <InfoNeed  
                        handlePopup={handlePopup} 
                        saveQuestion={saveQuestion} 
                        setName={setName} 
                        setId={setId}
                        type='edit'
                        />
                    </Modal>
                )}
                {popupValue === 'addNeedsToSet' && (
                <Modal show={true}  handlePopup={handlePopup}>
                    <AddNeedsToSet 
                    handlePopup={handlePopup}
                    handleHeaderPopup={handleHeaderPopup} 
                    sidebarList={sidebarList}
                    selectedNeeds={selectedQuestions}
                    saveNeedsToSet={saveNeedsToSet}
                    getInfoNeed={getInfoNeed}
                    needId={needId}
                    loaderRef={loaderRef}
                    />
                </Modal>    
                )}
                {popupValue === 'uploadInfoNeed' && (
                <Modal show={true}  handlePopup={handlePopup}>
                    <UploadInfoNeeds 
                    handlePopup={handlePopup}
                    handleHeaderPopup={handleHeaderPopup} 
                    sidebarList={sidebarList}
                    selectedNeeds={selectedQuestions}
                    uploadQuestions={uploadQuestions}
                    getInfoNeed={getInfoNeed}
                    needId={needId}
                    loaderRef={loaderRef}
                    />
                </Modal>    
                )} 
            <div className="search_clear_outer">
                {searchTerm && (<div className="clear_search">
                <span onClick={() => { return setSearchTerm('') }} className="pointer main_searchclear_box">
                    <strong>Search:</strong>
                    <div>{searchTerm}</div>
                    <span>&#10005;</span>
                </span>
                </div>)}
                {(filters.length > 0) && (
                filters.map((filter, index) => (
                    <div className = "clear_filter" key={index}>
                    <span onClick={() => { return removeFilter(filter.field) }} className="pointer main_searchclear_box">
                        <strong>{filter.field.charAt(0).toUpperCase() + filter.field.slice(1)}:</strong> {filter.values.map((val, index) => (
                        <div key={index}>
                            {typeof val === 'object' ? val.name : val} {filter.values.length !== index + 1 && ' , ' }
                        </div>))}
                        <span>&#10005;</span>
                    </span>
                    </div>
                ))
                )
                }
                {(searchTerm || filters.length > 0) && (
                <div className = "clear_all">
                    <span onClick={() => { reset(); return setSearchTerm('') }} className="pointer main_searchclear_box"><strong>Clear All</strong></span>
                </div>
                )
                }
            </div>
            <div className="paging-sorting-outer">
                <PagingInfo className="custom-page-info"/> 
                <Sorting
                    className="custom-sorting"
                    label={'Sort by'}
                    sortOptions={[
                    {
                        name: 'Title',
                        value: 'core:text',
                        direction: 'asc'
                    },
                    ]}
                />
            </div>
            {isLoading && (<div>Loading....</div>)}
            {results.length === 0 && !isLoading && <div>No Results Found</div>}
            { results.length > 0 && (
                <Results resultView={(props) =>  {   
                    return (
                    <ResultCard props={props} getInfoNeed={getInfoNeed}  toggle={toggle} selectedQuestions={selectedQuestions}/>
                    )
                }
                
                }
                />)
            }
            </div>
            </div>
            </div>
    </>
    )
    }
}

export default MainSearchTemplate
