import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import './index.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));
/**
 * @description MenuListComposition component for rendering header right sidebar menu items
 * @param {*} data 
 */
export default function MenuListComposition(data) {
  const classes = useStyles();
  const {handlePopup} = data
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, popupValue) => {
    handlePopup(popupValue)
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);

  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>      
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          Add NEW
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={(event)=> {handleClose(event, '')}}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem  onClick={(event)=> {handleClose(event, 'infoNeed')}}><span className="icon-layers"></span> Info Need</MenuItem>
                    {/* <MenuItem className="active" ><span className="icon-layers"></span> Info Need</MenuItem> */}
                    <MenuItem className="" onClick={(event)=> {handleClose(event, 'addQuestionSet')}}><span className="icon-folder-alt"></span> Info Need Set</MenuItem>
                    <MenuItem onClick={(event)=> {handleClose(event, 'uploadInfoNeed')}}><span className="icon-cloud-upload"></span> Upload Info Needs</MenuItem>
                    {/* <MenuItem ><span className="icon-cloud-upload"></span> Upload Info Needs</MenuItem> */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow> 
          )}
        </Popper>
      </div>
    </div>
  );
}