import React from 'react';
import {filterMetaValue} from '../../utils/helper'
import {find} from 'lodash'
import Radio from '@material-ui/core/Radio';
/**
 * @description Meta data component
 */
export default function MetaData(data) {
    const { metaData, updateMetaValue, metaPopup, setMetaPopVal,rightPopMetaValue} = data
    let values = metaData.values
    const [value, setValue] = React.useState('')
    const [checked, setChecked] = React.useState({
        checkedA: false,
        checkedB: true,
    });
    const handleClick = (value, id, multi) => { 
        rightPopMetaValue(value, id, multi)
    }
    return (
        <>
            <div className={metaData.id === metaPopup ? 'info-modal_footerBlock_BodyContent  active' : 'info-modal_footerBlock_BodyContent '} >
            {metaData.field !== 'text' && (
                <div>
                <div className="info-modal_footerBlock_subHeading">
                <span className="icon-compass"></span>
                    {metaData.field.toUpperCase()}
                </div>
                {values.length > 0 &&  metaData.type !== 'string' && metaData.type !== 'boolean' && (
                    <div>
                    <div className="info-modal_footerBlock_selectItem">
                        {
                            values.map((val, index) => {
                                let p = metaData.labelCode + ":" + val
                                let metaValObj = find(metaData.metaList, (o) => o['@id'] === p)
                                return (
                                    <div key={index}  className="cursor-set" onClick={(e) =>{e.preventDefault();updateMetaValue(val, metaData.id)}}>
                                        {metaValObj['rdfs:label']}
                                        <span className="fa fa-times" ></span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    </div>  
                    )}
                    { 
                            metaData.type === 'boolean' && (
                                <>
                                <Radio
                                checked={metaData.values[0] && (metaData.values[0] === 'true' || metaData.values[0].includes('true'))
                                    ? true : metaData.values[0] && (metaData.values[0] === 'false' || metaData.values[0].includes('false'))? false : checked.checkedA}
                                color="primary"
                                value="true"
                                onChange={(e) => {e.preventDefault(); 
                                    
                                    let value = !checked.checkedA || checked.checkedB ? 'true' : '' 
                                    if(value) {
                                        handleClick(value, metaData.id, metaData.multiValued)
                                    } else {
                                        updateMetaValue('true', metaData.id)
                                    }
                                    setChecked({checkedA: !checked.checkedA , checkedB: false})
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />True
                            <Radio checked={metaData.values[0] && (metaData.values[0] === 'false' || metaData.values[0].includes('false'))?
                                true : metaData.values[0] && (metaData.values[0] === 'true' || metaData.values[0].includes('true')) ? false : checked.checkedB}
                            color="primary"
                            value="false"
                            onChange={(e) => {e.preventDefault();
                                let value = !checked.checkedB || checked.checkedA ? 'false' : '' 
                                if(value) {
                                    handleClick(value, metaData.id, metaData.multiValued)
                                } else {
                                    updateMetaValue('false', metaData.id)
                                }
                                    setChecked({checkedB: !checked.checkedB, checkedA: false})
                                }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />False
                            </>
                            )  
                            
                        }
                        {
                            metaData.type === 'string' && (
                                <div className="related_question_Body_search keywordsOuter">                        
                                    <div className="search_outer">
                                        {
                                            metaData.field !== 'author' && (
                                                <div className="addEvent" onClick={(e) => {
                                                    e.preventDefault();
                                                    if(value) {
                                                        handleClick(value, metaData.id, metaData.multiValued)
                                                        setValue('')
                                                    }
                                                }}>
                                                    <i className="fa fa-plus"></i>
                                                </div>
                                            )
                                        }
                                        
                                        <input 
                                        value = { metaData.field === 'author' && metaData.values[0] ? metaData.values[0] : value}
                                        placeholder={"Enter " + metaData.field}
                                        style={{padding: metaData.field === 'author' ? "" : "7px 7px 7px 25px"}}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            // if(e.target.value) {
                                                setValue(e.target.value); 
                                            // }
                                            if (metaData.field === 'author') {
                                                handleClick(e.target.value, metaData.id, metaData.multiValued)
                                            }}}
                                            
                                        onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            if(e.target.value) {
                                                handleClick(e.target.value, metaData.id, metaData.multiValued)
                                                setValue('')
                                            }
                                            e.preventDefault()
                                            }
                                            }}/>
                                    </div>
                                </div>
                            ) 
                        }
                        {
                            metaData.type === 'string' && metaData.field !== 'author' && (
                                values.map((val, index) => {
                                    return (
                                        <div className="keyword-outer cursor-set" key={index} onClick={(e) =>{e.preventDefault();updateMetaValue(val, metaData.id)}}>
                                            {val}
                                            <span className="fa fa-times" ></span>
                                        </div>
                                    )
                                })   
                            ) 
                        }
                        {
                            metaData.type !== 'string' && metaData.type !== 'boolean' && (
                                <div className="showMore_item" onClick={(e) => {
                                    e.preventDefault();
                                    setMetaPopVal(metaData.id)
                                }}>
                                    {metaData.id !== metaPopup  && (
                                    <span className="fa fa-plus" ></span>
                                    )}
                                </div>
                            )
                        }
                    {
                        metaData.id === metaPopup  && metaData.type !== 'string' && metaData.type !== 'boolean' && (
                            <div>
                                <div className="dropDown-multipleOpt">
                                    <div className="arrow-left"></div>
                                    <div className="menuContent">
                                        <div className="dropDown-headingOuter">
                                            <div className="dropDown-Heading">{metaData.field.toUpperCase()}</div>
                                        </div>
                                        <ul>
                                            {metaData.metaList.map((data, index) => {
                                                return (
                                                    <div key={index}>
                                                        <li className="cursor-set" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleClick(filterMetaValue(data['@id']), metaData.id, metaData.multiValued)
                                                                    }} >
                                                            <span>{data['rdfs:label']}</span>
                                                            {
                                                                values.includes(filterMetaValue(data['@id'])) && (
                                                                    <span className="fa fa-check"></span>
                                                                )
                                                            }
                                                            {
                                                                values.includes(filterMetaValue(data['@id'])) === false && (
                                                                    <span className="fa fa-plus"></span>
                                                                )
                                                            }
                                                            
                                                        </li>
                                                    </div>            
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        )
                    }
                </div>
            ) }
            </div>
        </>
    )
}