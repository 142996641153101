import React from "react"
import instance from "../config/axios-config"
import SignIn from '../components/login/login'
import Spinner from '../components/spinner/spinner'
import {correctEmail, onLogin} from '../utils/authentication'
import CONSTANTS from "../../constants"
const {EMAIL_INCORRECT } = CONSTANTS
/**
 * @desc Login component to handle login functionality
*/
class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        error: false,
        message: "",
        loader: false,
        }
        this.loaderRef = React.createRef();
    }
    /** 
     * @description This will handle login form after submit
     * */
    loginSubmit = async (event) => {
        event.preventDefault();
        
        //e
        let {password, email}  = event.target

        /** If both email and password are true*/
        if (!correctEmail(email.value) ) {
            this.setState({error: true, message: EMAIL_INCORRECT})
            return 
        } 
        this.setState({error: false, message: ""})
        /** If both email and password are true*/
        if(email.value && password.value) {
            this.setState({loader: true})
            await this.login({ email: email.value, password: password.value })
        }
        
    }
    /**@desc login request */
    login = (loginData) => {
        this.loaderRef.current && this.loaderRef.current.showLoader()
        return instance.post("/login", loginData)
            .then(res => {
                this.loaderRef.current && this.loaderRef.current.hideLoader()
                onLogin(res.data ? res.data : {});
            })
            .catch((err) => {
                this.loaderRef.current && this.loaderRef.current.hideLoader()
                const message = err.response && err.response.data  && err.response.data.message ? err.response.data.message  : ''
                this.setState({error: true, message, loader: false})
            })
    }
    render () {
        const { error, message } = this.state
        return (
        <div>
            {
                <div>
                    <Spinner ref = {this.loaderRef}/>
                    <SignIn loginSubmit={this.loginSubmit} error={error} message={message}/>
                </div>
            } 
        </div>
        )
    }
}
export default Login