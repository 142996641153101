import React, { Component } from 'react'
import MenuListComposition from '../menu-list/index'
import { SearchBox } from  '@elastic/react-search-ui'
/**
 * @description Header  component for header view
 */
class HeaderTemplate extends Component {
render () {
    const { handlePopup, handleBox, setId, setName  } = this.props
    return (
    <>
        <div className="searchBox-outer">
            <div className="searchInputBox">
                {/* <SearchBox autocompleteSuggestions={false} searchAsYouType={true} debounceLength={1000} className="custom-search"/> */}
                <SearchBox autocompleteSuggestions={false} searchAsYouType={true} debounceLength={1000} className="custom-search"/>
            </div>
            <div className="searchButtonBox">
                <MenuListComposition handlePopup={handlePopup}/>
            </div>
        </div>
        <div>
            {setId && (<div className="bitCoin-outer" > 
                <div className="bitCoin-logo">
                    <span className="fa fa-folder-open"></span> {setName}
                </div>
                <div className="bitCoin-delete" onClick={handleBox}><span className="icon-trash"></span></div>
            </div>)}
        </div>
    </>
    )
  }
}

export default HeaderTemplate
