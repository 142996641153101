import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

// const useStyles = makeStyles((theme) => ({
//     modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// },
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     border: '0px',
//     maxWidth: 485,
//   },
// }));

export default function ModalComponent(data) {
  // const classes = useStyles();
  const [open, setOpen] = React.useState(data.show);

//   const handleOpen = () => {
//     setOpen(true);
//   };

  const handleClose = () => {
    data.handlePopup('')
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modalOuter"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        disableBackdropClick={true}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modal-dialog-outer">
            {data.children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
