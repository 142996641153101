import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import { SecondaryListItems } from '../sidebar-items/sidebar-items'
import { onLogout, isLoggedIn } from '../../utils/authentication'
import history from '../../../history'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { useStyles } from './style'
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}
let sidebarMobFlag = false
/**
 * @description SidebarMenu component
 */
export default function SidebarMenu (data) {
  const classes = useStyles()
  // let collapseFlag = false
  const userName = localStorage.getItem('SP_USER')

  const [activeClass, setActiveClass] = React.useState(history.location.pathname)

  if(activeClass !== history.location.pathname) {
    setActiveClass(history.location.pathname)
  }

  const [open, setOpen] = React.useState(true)
  const handleDrawerClose = () => {
    setOpen(!open)
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  if (windowDimensions.width < 991 && open) {
    if (!sidebarMobFlag) {
      sidebarMobFlag = true
      setOpen(false)
    }
  } else if (windowDimensions.width > 991 && !open) {
    sidebarMobFlag = false
    setOpen(true)
  }

  if (!isLoggedIn()) {
    return onLogout()
  }
  return (
    <div className="left-side">
      <CssBaseline />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >

        <div className={open ? 'sidebarMenu-outer' : 'mobileMenu sidebarMenu-outer'}>
          <div className="top-sidebar">
            <div className={classes.toolbarIcon} >
              <img className="covid-logo" alt="img" src={require('../../..//assets/INDB-logo.png') } onError={(e) => {
              // e.target.src = '../../../assets/no-image.png'
                e.target.style = 'visibility: hidden;' // inline styles in html format
              }} width="102"></img>
              <img className="covid-logo-symbol" alt="img" src={require('../../..//assets/covid-19-logo-symbol.png') } onError={(e) => {
              // e.target.src = '../../../assets/no-image.png'
                e.target.style = 'visibility: hidden;' // inline styles in html format
              }} width="25"></img>
              <div onClick={handleDrawerClose} className="toggleMenu">
                <ChevronLeftIcon />
              </div>
            </div>
            <Divider />
            <List>{SecondaryListItems(data.setsData, data.handleSetSearch,activeClass, setActiveClass, data.infoTotalCount)}</List>
          </div>

          <div className="bottom-sidebar">
            <div className="outer-sidebar-logout">
              <div className="logout-user-img"></div>
              <div className="logout-user-text">
                <div className="logout-user-name">{userName}</div>
                <div className="logout-app" onClick= {() => { return onLogout() }}>Logout</div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
