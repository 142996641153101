
import React, { Component } from 'react'
import MainTemplate from '../components/main/main'
import Spinner from '../components/spinner/spinner'
//import { onLogout } from '../utils/authentication'
import Sidebar from './sidebar'
import instance from "../config/axios-config"
import CONSTANTS from '../../constants'
import SnackbarComponent from '../components/snackbar'
import {setMetaValue, isBase64, setSubMetaValue, filterMetaValue, getSubValue} from '../utils/helper'
import history from '../../history'
import base64 from "base-64"
import ConfirmationBox from '../components/UI/confirm-box'
import ErrorBoundary from '../components/error-boundary'
const {
  CREATE_INFO_NEED_SET,FACETS_OBJ, ADD_QUESTION_OBJ, MESSAGES, SUCCESS,
  ERROR, WARNING
} = CONSTANTS
const DEFAULT_STATE = {
      loadData: false,
      loader: false,
      error: false,
      snackMsg: '',
      snack: false,
      windowDimensions: { width: 0, height: 0 },
      sidebarList: [],
      popupValue: '',
      facets: [],
      action: '',
      selectedQuestions: [],
      position: window.pageYOffset,
      needId: '',
      showConfirmationBox: false,
      metaDataCodes: [],
      resetSearchBool: true
}
/**
 * @description Main container component
 */
class Main extends Component {
  constructor (props) {
    super(props)
    this.state = {...DEFAULT_STATE}
    this.snackbarRef = React.createRef();
    this.loaderRef = React.createRef();
  }
  

  /** @desc When component will setup */
  componentDidMount () {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    this.setState({loader: true, popupValue: ''}) 
    this.loaderRef.current && this.loaderRef.current.showLoader()
    instance.post('/facets', {searchObj: FACETS_OBJ})
    .then((res) => {
      const facetItems =  res.data.data.items
      let arr = []
      facetItems.map((item) => {
        arr.push({
          field: filterMetaValue(item['@id']),
          fieldLabel: getSubValue(item['@id']),
          valueLabel: item['rdfs:range'] && item['rdfs:range']['meta:instance'] ? getSubValue(item['rdfs:range']['meta:instance'][0]['@id']) : 'in'
        })
        return true
      })
      this.setState({loader: false, error: false, loadData: true, facets: res.data.data.items, metaDataCodes: arr})
    })
    .catch((err) => {
      this.loaderRef.current && this.loaderRef.current.hideLoader()
      this.setState({loader: false, loadData: true})
      this.snackbarRef.current.openSnackBar(MESSAGES.SOMETHING_WENT_WRONG, ERROR)
    })
    .finally(() => { this.loaderRef.current && this.loaderRef.current.hideLoader()})
  }
  /**
   * @description Component componentDidUpdate
   */
  componentDidUpdate (prevProps) {
    window.scrollTo(0,0)
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const facets = this.state.facets
      const sidebarList = this.state.sidebarList
      this.setState(
        {...DEFAULT_STATE, popupValue: '', needId: '',
      loadData: true, facets, sidebarList, selectedQuestions: []})
      
    }
  }
  /**
   * @description When component will un-mount
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  /**
   * @description show warning
   */
  showWarning = (msg) => {
    this.snackbarRef.current.openSnackBar(msg, WARNING)
  }
  /**
   * @description reset selection
   */
  resetSearch = () => {
    this.setState({resetSearchBool: !this.state.resetSearchBool, popupValue: '', needId: ''})
  }
  //TODO cleanup for snack and snackmsg
  /**
   * @description save question set
   */
  saveQuestionSet = (qSetValue) => {
    this.setState({loader: true, popupValue: '', action: ''})
    this.loaderRef.current && this.loaderRef.current.showLoader()
    CREATE_INFO_NEED_SET['dc:title'] = qSetValue
    instance.post('/add-question-set', CREATE_INFO_NEED_SET)
    .then((res) => {
      const data = res.data.data ? res.data.data : ''
      const idUrl = data['@id'] ?  data['@id'] : ''
      if(idUrl) {
        const arr = idUrl.includes('#') ? idUrl.split("#") : [0, idUrl]
        let id = arr[1] ? arr[1] : ''
        if(id.includes(':')) {
          id = filterMetaValue(id)
        }
        if(id) {
          history.push('/thought-leadership/'+id+'/'+base64.encode(qSetValue))
          //history.push('/thought-leadership')
        }
      }
      this.setState({loader: false, error: false, loadData: true, snack: true, snackMsg: ''})
      this.snackbarRef.current.openSnackBar(MESSAGES.QUESTION_SET_ADDED, SUCCESS)
    })
    .catch((err) => {
      this.setState({loader: false, loadData: true})
      this.snackbarRef.current.openSnackBar(MESSAGES.SOMETHING_WENT_WRONG, ERROR)
    })
    .finally(() => {this.loaderRef.current && this.loaderRef.current.hideLoader()})
  }
  
  /**
   * @description Handle popup
   */
  handleHeaderPopup = (value, needId = '') => {
    this.setState({popupValue: value, needId: needId})
  }
  /**
   * @description Update sidebar list to main 
   */
  updateSidebarList = (list) => {
    this.setState({sidebarList: list})
  }
  /**@description Delete info set
   * 
   */
  deleteInfoSet = (setId) => {
    this.setState({loader: true,  popupValue: '', action: 'delete'})
    this.loaderRef.current && this.loaderRef.current.showLoader()
    instance.delete('/delete-set', {data: {setId: setMetaValue(setId)}})
    .then((res) => {
      history.push('/thought-leadership')
      this.snackbarRef.current.openSnackBar(MESSAGES.QUESTION_SET_DELETED, SUCCESS)
      
    })
    .catch((err) => {
      this.setState({loader: false, loadData: true})
      this.snackbarRef.current.openSnackBar(MESSAGES.SOMETHING_WENT_WRONG, ERROR)
    })
    .finally(() => {
      this.loaderRef.current && this.loaderRef.current.hideLoader()
    })
  }
  /**
   * @description Save info need
   */
  saveQuestion = (value, metaDataList, type) => {
    const localObj = {...ADD_QUESTION_OBJ}
    metaDataList.forEach((data) => {
      if(data.values.length > 0 ) {
        let metaCode = data.labelCode
        if(data.multiValued === 'false') {
          if(data.type === 'string') {
            if(data.field === 'keyword') { 
              localObj['core:keyword'] =  data.values[0]
            } else {
              localObj[data.id] =  data.values[0]
            }
            
          } else if (data.type === 'boolean') {
        
            localObj[data.id] =  typeof data.values[0] === 'string' ? data.values[0] === 'true' ? true: false : data.values[0]
          } else {
            localObj[data.id] = {"@id":setSubMetaValue(metaCode, data.values[0])}  
          }
          
        } else {
          if(data.field === 'importance') {
            localObj[data.id] = {"@id":setSubMetaValue(metaCode, data.values[0])}

          } else {
            localObj[data.id] = data.values.map((item) => {
              if(data.type === 'string') {
                return item
              } else if (data.type === 'boolean') {
                return typeof item === 'string' ? Boolean(item) : item
              } else {
                return {"@id":setSubMetaValue(metaCode, item)}   
              }
            })
          }
        }
        
      }
      
    })
    this.loaderRef.current && this.loaderRef.current.showLoader()
    this.setState({loader: true,  popupValue: ''})
    localObj['core:text'] = value.trim()
    if(type === 'edit') {
      localObj['id'] = this.state.needId
      instance.post('/update-question', localObj)
      .then((res) => {
        this.setState({loader: false, error: false, loadData: true})
        history.push('/thought-leadership')
        this.snackbarRef.current.openSnackBar(MESSAGES.QUESTION_UPDATED, SUCCESS)
        
      })
      .catch((err) => {
        this.setState({loader: false, loadData: true})
        this.snackbarRef.current.openSnackBar(MESSAGES.SOMETHING_WENT_WRONG, ERROR)
      })
      .finally(() => { this.loaderRef.current && this.loaderRef.current.hideLoader()})
    } else {
      instance.post('/add-question', localObj)
      .then((res) => {
        this.setState({loader: false, error: false, loadData: true})
        history.push('/thought-leadership')
        this.snackbarRef.current.openSnackBar(MESSAGES.QUESTION_ADDED, SUCCESS)
        
      })
      .catch((err) => {
        this.setState({loader: false, loadData: true})
        this.snackbarRef.current.openSnackBar(MESSAGES.SOMETHING_WENT_WRONG, ERROR)
      })
      .finally(() => { this.loaderRef.current && this.loaderRef.current.hideLoader()})
    }
  }
  /**
   * 
   * @param {*} infoNeedId 
   */
  uploadQuestions = (questionsData) => {

    this.loaderRef.current && this.loaderRef.current.showLoader()
    this.setState({loader: true,  popupValue: ''})
    instance.post('/upload-questions',questionsData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }})
      .then((res) => {
        this.setState({loader: false, error: false, loadData: true})
        history.push('/thought-leadership')
        if(res.data && res.data.data &&  res.data.data.ok === false) {
          this.snackbarRef.current.openSnackBar(MESSAGES.BAD_REQUEST, ERROR)
        } else {
          this.snackbarRef.current.openSnackBar(MESSAGES.QUESTION_UPLOADED, SUCCESS)
        }
      })
      .catch((err) => {
        this.setState({loader: false, loadData: true})
        this.snackbarRef.current.openSnackBar(MESSAGES.SOMETHING_WENT_WRONG, ERROR)
      })
      .finally(() => { this.loaderRef.current && this.loaderRef.current.hideLoader()})
  }
  /**
   * @description Detail info need
   */
  getInfoNeed = (infoNeedId) => {
    this.setState({popupValue: 'infoNeed', needId:infoNeedId})
  }
  /**
   * @description Handle bulk delete confirmation box
   */
  handleNeedsToSetBox = (questions, value) => {
    this.setState({popupValue: value, snack: false, needId: '',  selectedQuestions: questions ? questions : []})
  }

  /**
   * @description Update window dimensions
   */
  updateDimensions = () => {
    this.setState({ windowDimensions: {width: window.innerWidth, height: window.innerHeight }});
  }
  render () { 
    const { loadData, loader, error, windowDimensions, resetSearchBool } = this.state
    const setName = this.props.match.params.name &&  isBase64(this.props.match.params.name) ? 
    base64.decode(this.props.match.params.name)  : ''
    return (
        <>
          <SnackbarComponent ref = {this.snackbarRef} />
          <Spinner ref = {this.loaderRef}/>
          {!loader && loadData && resetSearchBool
            ? <>
              <ErrorBoundary>
              <Sidebar updateSidebarList={this.updateSidebarList} loaderRef={this.loaderRef}/>
              <MainTemplate 
                windowDimensions={windowDimensions}
                loaderRef={this.loaderRef}
                key={this.props.location.key} 
                saveQuestionSet={this.saveQuestionSet}
               // saveNeedsToSet={this.saveNeedsToSet}
                handleHeaderPopup={this.handleHeaderPopup}
                deleteInfoSet={this.deleteInfoSet}
                handleNeedsToSetBox={this.handleNeedsToSetBox}
                // masterToggle={this.masterToggle}
                // toggle={this.toggleSelection}
                resetSearch={this.resetSearch}
                showWarning={this.showWarning}
                saveQuestion={this.saveQuestion}
                getInfoNeed={this.getInfoNeed}
                uploadQuestions={this.uploadQuestions}
                setId={this.props.match.params.id ? this.props.match.params.id : ''}
                setName={setName}
                props={this.state}
                snackRef={this.snackbarRef}
                // handleBulkDeleteBox={this.handleBulkDeleteBox}
              />
              </ErrorBoundary>
              {this.state.showConfirmationBox && 
            (<ConfirmationBox deleteInfoSet={this.handleBulkDelete}
            handleBox={this.handleBulkDeleteBox}
            title={CONSTANTS.CONFIRM_BOX_DELETE_QUESTION_TITLE}
            message={CONSTANTS.CONFIRM_BOX_DELETE_QUESTION_MESSAGE}
            />)}
            </> : error ? (<div>
            </div>) : (<div></div>)
          }
        </>
    )
  }
}
export default Main
