import React from 'react';
import MetaData from './meta-data'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


/**
 * @description Add new need component
 */
export default function AddInfoNeedTemplate(data) {
    const {saveQuestion, handlePopup, metaDataList, updateMetaValue, metaPopList,
            metaPopup, setMetaPopVal, allowSubmit, type, needId, needData, deleteNeed,
            rightPopMetaValue, addInputKeywords, toggle, handleHeaderPopup} = data
    const [flag, setFlag] = React.useState(false)
    const [value, setValue] = React.useState('')
    
    React.useEffect(() => {
        if(needData['core:text'] && !value && !flag) { 
            setValue(needData['core:text'])
            setFlag(true)
        }
    },[needData, value, flag])
    return (
        <>
            <div className="info-modal">
                <div className="info-modal-bodyContent">         
                    {type === 'add' && (
                        <div className="info-modal_ContentBlock">
                            <div className="related_question_Header">
                                Add New Info Need
                            </div>
                            <div className="related_question_Body">
                            
                            <div className="related_question_Body_search">                        
                                <div className="search_outer">
                                    <input placeholder="Add New Info Need" onChange={(e) => {e.preventDefault();setValue(e.target.value)}}/>
                                </div>
                            </div>
                            </div>                
                        </div>
                    )}
                    {type === 'edit' && (
                        <div className="info-modal_headingBlock">
                            {/* <h2>{needData['core:text']}</h2> */}
                            <div className="related_question_Body_search">                        
                                <div className="search_outer">
                                    <input placeholder="Info Need Text" value={value || ''} onChange={(e) => {e.preventDefault();setValue(e.target.value)}}/>
                                </div>
                            </div>
                            <div className="info_question_status">
                                <div className="info_question_createdBy">
                                    <div className="createdBy_title">Created by:</div>
                                    <div className="createdBy_value">{needData['schema:author'] && needData['schema:author'][0] && typeof needData['schema:author'][0] === 'string' ? needData['schema:author'][0] : ''}</div>
                                </div>
                                <div className="info_question_Select">
                                    <div className="status_title">Status:</div>
                                    <div className="status_SelectOption">
                                    <Select>
                                        <MenuItem selected={true}>Reviewed</MenuItem>
                                    </Select>                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    
                    
                </div>
                <div className="info-modal-actionBtn">
                    <div className="action_btn" onClick={() => {handlePopup('');handleHeaderPopup('')}}>
                        <div className="action_btn_icon">
                            <span className="fa fa-times"></span>
                        </div>
                        <div className="action_btn_value">
                            Close [Esc]
                        </div>
                    </div>         
                    {
                        type === 'edit' && (
                            <>
                                <div className="action_btn" onClick={(e) => {e.preventDefault(); toggle(needId); handlePopup('addNeedsToSet');handleHeaderPopup('addNeedsToSet', needId)}}>
                                    <div className="action_btn_icon">
                                        <span className="fa fa-folder-open-o"></span>
                                    </div>  
                                    <div className="action_btn_value">
                                        Add to Info Need Set
                                    </div>
                                </div>
        
                                <div className="action_btn" onClick={(e)=>{e.preventDefault();deleteNeed(needId)}}>
                                    <div className="action_btn_icon">
                                        <span className="icon-trash"></span>
                                    </div>
                                    <div className="action_btn_value">
                                        Delete
                                    </div>
                                </div>
                            </>
                        )
                    }
                    <div className={allowSubmit && value ? "action_btn" : "action_btn action_btn_dark"} onClick={(e) => {
                        e.preventDefault();
                        if((allowSubmit && value)){ 
                            // let val = type === 'edit' ? needData['core:text'] : value
                            saveQuestion(value, metaDataList, type)}
                    }
                        }>
                        <div className={allowSubmit && value ? "action_btn_icon" : "action_btn_icon action_btn_dark"}>
                            <span className="fa fa-floppy-o"></span>
                        </div>
                        <div className={allowSubmit && value ? "action_btn_value" : "action_btn_value action_btn_dark"}>
                            Save
                        </div>
                    </div>         
                </div>
            </div>
            <>
            <div className="info-modal infoModal-Outer-BodyContent">
                <div className="info-modal-bodyContent">         
                    <div className="info-modal_footerBlock">
                        <div className="info-modal_footerBlock_Header">
                            INFO NEED METADATA        
                        </div>
                        {
                            metaDataList.map((metaData, index) => {
                                return (
                                    <MetaData 
                                    metaData={metaData}
                                    needData={needData} 
                                    needId={needId}
                                    type={type}
                                    key={index}
                                    metaPopup={metaPopup} 
                                    updateMetaValue={updateMetaValue}
                                    metaPopList={metaPopList}
                                    setMetaPopVal={setMetaPopVal}
                                    rightPopMetaValue={rightPopMetaValue}
                                    addInputKeywords={addInputKeywords}
                                    />
                                )
                            })

                        }  
                    </div>
                </div>
            </div>
        </>
        </>
    )
}