import axios from 'axios'
import { onLogout } from '../utils/authentication'
import constants from '../../constants'
const instance = axios.create({
  baseURL: constants.API_URL
})

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('SP_AUTH_TOKEN')

  if (token != null) {
    config.headers.Authorization = `${token}`
  }

  return config
}, function (err) {
  return Promise.reject(err)
})
/** Handle response data */
instance.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response) {
    /** Logout if any case authorization */
    const { status } = error.response
    if (status === 401 || status === 403) {
      onLogout()
    }
  }
  /** Response error */
  return Promise.reject(error)
})

export default instance
