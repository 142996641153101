
import React, { Component } from 'react'
import {
  Paging,
  Facet,
  SearchProvider,
  WithSearch
} from '@elastic/react-search-ui'
import PropTypes from 'prop-types'
import { Layout } from '@elastic/react-search-ui-views'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import './main.css'
import Header from '../../containers/header'
import { configurationOptions } from '../../config/search-config'
import SnackbarComponent from '../snackbar'
import { getAllQuestions, wrapSearchRequestObj, wrapSearchResponseObj } from '../../utils/wrapper'
import {setMetaValue, filterMetaValue, setSubMetaValue,setFacetValueReqObj } from '../../utils/helper'
import {find} from  'lodash'
import SearchContainer from '../../containers/search'
import CONSTANTS from '../../../constants'
const {
  COUNT_OBJ_All_INFO
} = CONSTANTS 
/**
 * @description Main component for search and filter operations using elastic search
 */
class MainTemplate extends Component {
  render () {
    const subscribed = true
    const { windowDimensions,  props, handleHeaderPopup, deleteInfoSet, setId, setName } = this.props
    const { width } = windowDimensions
    const {snackMsg, snack, error, popupValue, facets, metaDataCodes} = props
    configurationOptions['onSearch'] = async (state, queryConfig) => {
      let countObj = {...COUNT_OBJ_All_INFO}     // this.props.resetSearch()
      //resetSelection()
      const { searchTerm } = state
      const reqObj = wrapSearchRequestObj(state, queryConfig, metaDataCodes)
      state.filters.forEach((filter) => {
        let metaCodeObj = find(metaDataCodes, (meta) => {
            return meta.field === filter.field
        }) 
        countObj[setSubMetaValue(metaCodeObj.fieldLabel, filter.field)] = filter.values.map((val) => {
          return {"@id": setSubMetaValue(metaCodeObj.valueLabel, setFacetValueReqObj(val))}
        }) 
      })
      if(setId) { 
        reqObj['in:member of'] = {'@id':setMetaValue(setId) }
        countObj['in:member of'] = {'@id':setMetaValue(setId) }
        
      }
      countObj['core:text contains']  = searchTerm
      const response = await getAllQuestions(reqObj, countObj) 
      const resObj = wrapSearchResponseObj(response, state, facets)
      return resObj
      }
    return (
      <>

        <SearchProvider config={configurationOptions}>
          <WithSearch mapContextToProps={({
            results, isLoading, searchTerm,
            setSearchTerm, reset, clearFilters, filters, removeFilter, totalResults
          }) =>
            ({
              results,
              isLoading,
              searchTerm,
              setSearchTerm,
              reset,
              clearFilters,
              filters,
              removeFilter,
              totalResults
            })}>
            {
              ({
                results, isLoading, searchTerm, setSearchTerm,
                reset, filters, removeFilter,
                totalResults
              }) => {
                return (
                  <div className={width < 991 ? 'App search-filter-mob' : 'App'}>
                    {results && (
                      <Layout
                        className="main-container"
                        header = {
                          <>
                            {error && snack && snackMsg && (<div><SnackbarComponent message={snackMsg} type="error" /></div>)}
                            <div><Header handlePopup={handleHeaderPopup} setName={setName} deleteInfoSet={deleteInfoSet} setId={setId}/></div>                           
                          </>                          
                        }
                        bodyContent={
                          <SearchContainer
                          {...this.props}
                          results={results}
                          isLoading={isLoading}
                          searchTerm={searchTerm}
                          setSearchTerm={setSearchTerm}
                          reset={reset}
                          filters={filters}
                          totalResults={totalResults}
                          removeFilter={removeFilter}
                          popupValue={popupValue}
                          handleHeaderPopup={handleHeaderPopup}
                          />
                          
                        }
                        sideContent={
                          <div>
                            {subscribed && (
                              <>
                                {facets.map((facet, index) => {
                                  const val = filterMetaValue(facet['@id']) ? filterMetaValue(facet['@id']) : ''
                                  return (
                                    <Facet field={val} label={val.split("_").join(' ')}  isFilterable={true} show={100} key={index}/>
                                  )
                                })}
                              </>
                            ) }
                          </div>
                        }
                        bodyFooter={<Paging />}
                      />
                    )}
                  </div>
                )
              }}
          </WithSearch>
        </SearchProvider>
      </>

    )
  }
}
/** MainTemplate component prop types */
MainTemplate.propTypes = {
  props: PropTypes.object,
  windowDimensions: PropTypes.object

}
export default MainTemplate
