const COMMON_CONTEXT_OBJECT = {
  "rdfs": "http://www.w3.org/2000/01/rdf-schema#",
  "in": "http://www.mind-alliance.com/ontologies/infoneed#",
  "core": "http://www.mind-alliance.com/ontologies/core#",
  "meta": "http://www.mind-alliance.com/ontologies/meta#",
  "law": "http://www.mind-alliance.com/ontologies/law#",
  "gui": "http://www.mind-alliance.com/ontologies/gui#",
  "dc" : "http://purl.org/dc/terms/",
  "foaf": "http://xmlns.com/foaf/0.1/",
  "xsd": "http://www.w3.org/2001/XMLSchema#",
  "schema": "http://schema.org/"
}
const CONSTANT =  {
  "SEARCH_KEY": 'search-w1mau52jjebi1yar1b1ftnj9',
  "ENGINE_NAME": 'search-project-dev',
  "ENDPOINT_BASE": 'https://appsearch.mindpeer.com',
  "API_URL": 'https://indbapi.mindpeer.com',
  //"API_URL": 'http://192.168.8.161:5000',
  "PAGE": {
    "PER_PAGE": 20,
    "CURRENT": 1
  },
  "SORT": {
    "FIELD": 'rdfs:label',
    "DIRECTION": 'desc'
  },
  "SEARCH_OBJ": {
    "@context": COMMON_CONTEXT_OBJECT,
    "@id": null,
    "@type": "in:Question",
    "core:text contains": "",
    "rdfs:label ?": null,
    "in:importance ?":null,
    "in:industry ?": [],
    "core:information_status ?": null,
    "in:has_answer_status ?": null,
    "in:relevant_for ?": [],
    "in:relevant_for_natural_hazard ?": [],
    "in:relevant_for_practicearea ?": [],
    "in:relevant_for_tech_hazard ?": [],
    "schema:geographicArea ?": [],
    "schema:author ?":null,
    "core:keyword ?":[],
    "in:entity_referring ?": null,
    "meta:query_options": {
      "meta:sort_by": 'core:text',
      "meta:sort_direction": 'ASC',
      "meta:results_limit": 20,
      "meta:results_offset": 0
      }
  },
  
  "COUNT_OBJ_All_INFO": {
    "@context": COMMON_CONTEXT_OBJECT,
    "@id":null,
    "@type": "in:Question",
    "core:text contains": "",
    "meta:count":null
  },
  "LIST_INFO_SET_REQ_OBJ": {
    "@context": COMMON_CONTEXT_OBJECT,
    "@id": null,
    "@type": "in:InformationNeedSet",
    "dc:title": null,
    "dc:description": null,
    "in:member ?":[]
  },
  "CREATE_INFO_NEED_SET": {
    "@context": COMMON_CONTEXT_OBJECT,
    "dc:description": ""
  },
  "SAVE_NEEDS_TO_SET": {
    "@context": COMMON_CONTEXT_OBJECT,
  },
  "FACETS_OBJ": {
    "@context":  {
      "rdfs": "http://www.w3.org/2000/01/rdf-schema#",
      "in": "http://www.mind-alliance.com/ontologies/infoneed#",
      "core": "http://www.mind-alliance.com/ontologies/core#",
      "meta": "http://www.mind-alliance.com/ontologies/meta#",
      "gui": "http://www.mind-alliance.com/ontologies/gui#",
      "law": "http://www.mind-alliance.com/ontologies/law#",
      "dc" : "http://purl.org/dc/terms/",
      "foaf": "http://xmlns.com/foaf/0.1/"
      },
    "@id": null,
    "@type": "meta:EntityProperty",
    "meta:for_type": "in:Question",
    "rdfs:range": {
    "rdf:type of": [{"rdfs:label": null, "@id": null}]
    },
    "gui:field": {
      "@id":null,
      "gui:is-searchable": null
    } 
  },
  "ADD_QUESTION_OBJ": {
    "@context": COMMON_CONTEXT_OBJECT,
    "@type": "in:Question",
    "core:text": "",
  },
  "META_DATA_RANGE_VALUES": [
    {"rdf:type of": [{"rdfs:label": null, "@id": null}]},
    {"@id":"xsd:string"},
    {"@id":"xsd:boolean"}
  ],
  "META_DATA_OBJ": {
    "@context": {
      "rdfs": "http://www.w3.org/2000/01/rdf-schema#",
      "in": "http://www.mind-alliance.com/ontologies/infoneed#",
      "core": "http://www.mind-alliance.com/ontologies/core#",
      "meta": "http://www.mind-alliance.com/ontologies/meta#",
      "law": "http://www.mind-alliance.com/ontologies/law#",
      "gui": "http://www.mind-alliance.com/ontologies/gui#",
      "dc" : "http://purl.org/dc/terms/",
      "foaf": "http://xmlns.com/foaf/0.1/",
      "xsd": "http://www.w3.org/2001/XMLSchema#",
      "schema": "http://schema.org/"
    },
    "@id": null,
    "@type": "meta:EntityProperty",
    "rdfs:label ?":null,
    "meta:for_type": "in:Question",
    "meta:multivalued": null,
    "meta:has_default ?": null,
    "rdfs:range": {"rdf:type of": [{"rdfs:label": null, "@id": null}]},
    "gui:field": {
      "gui:is-searchable ?": null,
      "gui:field-required ?": null,
      "gui:field-order ?": null
    }
  },
  "REMOVE_NEEDS_FROM_SET": {
    "@context": COMMON_CONTEXT_OBJECT
  },
  "CONFIRM_BOX_TITLE": 'Delete Info Need Set',
  "CONFIRM_BOX_MESSAGE": 'Are you sure you want to delete the info need set?',
  "CONFIRM_BOX_DELETE_QUESTION_TITLE": 'Delete Info Need(s)',
  "CONFIRM_BOX_DELETE_QUESTION_MESSAGE": 'Are you sure you want to delete the info need(s)?',
  "MESSAGES" : {
    'SOMETHING_WENT_WRONG': 'Something went wrong, please try again',
    'BAD_REQUEST': 'Bad Request',
    'QUESTION_SET_ADDED': 'Info Need set added successfully',
    'QUESTION_ADDED_TO_SET': 'Info Need(s) added to set successfully',
    'QUESTION_SET_DELETED': 'Info Need set deleted successfully',
    'QUESTION_ADDED': 'Info Need saved successfully',
    'QUESTION_UPDATED': 'Info Need updated successfully',
    'QUESTION_UPLOADED': 'Info Need(s) uploaded successfully',
    'QUESTION_DELETED':'Info Need deleted successfully',
    'QUESTION_DELETED_ALL':'Info Need(s) deleted successfully',
    "QUESTION_DELETED_FROM_SET": "Info Need(s) deleted from set successfully"
  },
  "EMAIL_INCORRECT": "Email Not Correct",
  "SUCCESS": 'success',
  "ERROR": 'error',
  "WARNING": 'warning',

  "META_CODES": {
    "Industry" : 'core',
    "Importance": "in",
    "PracticeArea":"law",
    "QuestionImportance": "in",
    "AnswerStatus": "in",
    "InformationItemStatus":"core",
    'Relevant_for':'in',
    'Relevant_for_practicearea':'in',
    'Has_answer_status':'in',
    'GeographicArea': 'schema',
    'Information_status': 'in',
    'Relevant_for_natural_hazard':'in',
    'Relevant_for_tech_hazard':'in',
  },
  "CSV_SPEC": { 
    "triples":
      [
        [ {"iri":"in:Question_$uuid", "n":1}, "rdf:type", "in:Question" ],
        [ {"iriref": 1}, "core:text", { "col": 0, "@type": "xsd:string" }],
        [ {"iriref": 1}, "in:importance", { "col": 1, "identifying": "rdfs:label", "matchStyle": "phrase_match" }],
        [ {"iriref": 1}, "in:industry", { "col": 2, "identifying": "rdfs:label", "matchStyle": "phrase_match"}],
        [ {"iriref": 1}, "core:information_status", { "col": 3,  "identifying": "rdfs:label", "matchStyle": "phrase_match"}],
        [ {"iriref": 1}, "in:relevant_for", { "col": 4,  "identifying": "rdfs:label", "matchStyle": "phrase_match" }],
        [ {"iriref": 1}, "in:has_answer_status", { "col": 5, "identifying": "rdfs:label", "matchStyle": "phrase_match"  }],
        [ {"iriref": 1}, "in:relevant_for_practicearea", { "col": 6,  "identifying": "rdfs:label", "matchStyle": "phrase_match"  }],
        [ {"iriref": 1}, "schema:geographicArea", { "col": 7, "identifying": "rdfs:label", "matchStyle": "phrase_match"  }],
        [ {"iriref": 1}, "in:relevant_for_natural_hazard", { "col": 8, "identifying": "rdfs:label", "matchStyle": "phrase_match"  }],
        [ {"iriref": 1}, "in:relevant_for_tech_hazard", { "col": 9, "identifying": "rdfs:label", "matchStyle": "phrase_match"  }],
        [ {"iriref": 1}, "schema:author", { "col": 10, "@type": "xsd:string"   }],
        [ {"iriref": 1}, "core:keyword", { "col": 11, "@type": "xsd:string"  }],
        [ {"iriref": 1}, "in:entity_referring", { "col": 12, "@type": "xsd:string" }],
        [ { "col": 13, "@type": "in:InformationNeedSet", "identifying": "terms:title", "matchStyle": "phrase_match"  },  "in:member", {"iriref": 1}]

      ],
      "options" : {"value-separator" : ";" }
  }
}
export default  CONSTANT 
