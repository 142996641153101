import React from 'react';
/**
 * @description Question set popup component
 */
export default function AddQuestionSet(data) {
    const {saveQuestionSet, handlePopup, handleHeaderPopup} = data
    const [value, setValue] = React.useState('')
    return (
        <>
            <div className="common-modal-outer">
                <div className="modal-Heading">
                    CREATE NEW INFO NEED SET
                </div>
                <div className="modal-bodyContent">
                    <input type="text" placeholder="Name the new info need set here" onChange={(event) => {setValue(event.target.value)}}/>
                </div>
                <div className="modal-footer">
                    <button className="cancel_btn" onClick={() => {handlePopup('');handleHeaderPopup('')}}>CANCEL</button>
                    <button disabled={!value} className={value && 'save_btn'} onClick={(event) => {saveQuestionSet(value)}}><span className="icon-check"></span>SAVE</button>
                </div>
            </div>
        </>
    )
}