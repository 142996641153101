
import React, { Component } from 'react'
import UploadInfoNeedTemplate from '../components/question/upload-needs'
/**
 * @description UploadInfoNeeds container component 
 */
class UploadInfoNeeds extends Component {
    constructor (props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount = () => {
        //
    }
    render () {
        const {uploadQuestions, handlePopup, handleHeaderPopup } = this.props
        return (
            <>
            <UploadInfoNeedTemplate   
            handlePopup={handlePopup} 
            handleHeaderPopup={handleHeaderPopup}
            uploadQuestions={uploadQuestions}
            />
            </> 

        )
    }
}
export default UploadInfoNeeds
