
import React, { Component } from 'react'
import HeaderTemplate from '../components/header'
import ConfirmationBox from '../components/UI/confirm-box'
import CONSTANTS from '../../constants'
/**
 * @description Header container component 
 */
class Header extends Component {
    constructor (props) {
        super(props)
        this.state = {
            showConfirmationBox:  false,
        }
        
    }
    /**
     * @description Handle box confirmation
     */
    handleBox = () => {
        this.setState({showConfirmationBox: !this.state.showConfirmationBox})
    }
    /**
     * @description Delete question set
     */
    handleDeleteSet = () => {
        this.props.deleteInfoSet(this.props.setId)
    }

    render () {
        const { handlePopup, setId, setName} = this.props
        return (
            <>
            {this.state.showConfirmationBox && 
            (<ConfirmationBox deleteInfoSet={this.handleDeleteSet}
            handleBox={this.handleBox}
            title={CONSTANTS.CONFIRM_BOX_TITLE}
            message={CONSTANTS.CONFIRM_BOX_MESSAGE}
            />)}
            <HeaderTemplate 
                handlePopup={handlePopup}
                handleBox={this.handleBox} 
                setId={setId}
                setName={setName}
            />
            </> 

        )
    }
}
export default Header
