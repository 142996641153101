import instance from '../config/axios-config'
import {filterMetaValue, setSubMetaValue, getReadFacetValue, setFacetValueReqObj} from './helper'
import {find} from  'lodash'
import CONSTANTS from '../../constants'
const {SEARCH_OBJ}  = CONSTANTS
/**
 * @description Get wrap search request
 */
export const wrapSearchRequestObj = (reqState, reqConfigQuery, metaDataCodes) => {
    
    const { searchTerm, current, resultsPerPage, sortField, filters } = reqState
    const sortDirectionU = reqState.sortDirection ? reqState.sortDirection.toUpperCase() : 'ASC'    
    const offset = (parseInt(current) - 1) * parseInt(resultsPerPage);
    const baseObj = {...SEARCH_OBJ}
    baseObj["core:text contains"] = searchTerm
    baseObj["meta:query_options"][ "meta:sort_by"] = sortField
    baseObj["meta:query_options"]["meta:sort_direction"] = sortDirectionU
    baseObj["meta:query_options"]["meta:results_limit"] = resultsPerPage
    baseObj["meta:query_options"]["meta:results_offset"] = offset
    //filters.length 
    filters.forEach((filter) => {
        let metaCodeObj = find(metaDataCodes, (meta) => {
            return meta.field === filter.field
        })
        delete baseObj[setSubMetaValue(metaCodeObj.fieldLabel, filter.field)+' ?'];
        baseObj[setSubMetaValue(metaCodeObj.fieldLabel, filter.field)] = filter.values.map((val) => {
            return {"@id": setSubMetaValue(metaCodeObj.valueLabel, setFacetValueReqObj(val))}
        })
        // baseObj["*"] = null
    })
    return baseObj
}
/**
 * @description Get wrap search request
 */
export const wrapSearchResponseObj = (resObj, reqObj, facets) => {
    
    const { searchTerm, current, resultsPerPage } = reqObj
    const results = resObj.items ? resObj.items : []
    const count = resObj.count ? resObj.count : 0
    const resultsFilter = results.map((result) => {
        result['id'] = { raw: result["@id"]}     
       // result['qID'] = filterMetaValue(result["@id"])
        return result
    })
    const filterResultFacets = filterFacets(resultsFilter, facets)
    return {
        facets: filterResultFacets,
        // requestId: "sdsdsdsdsdsfwfwfw",
        resultSearchTerm: searchTerm,
        results: resultsFilter,
        resultsPerPage,
        current,
        totalPages: Math.ceil(count/resultsPerPage),
        totalResults: count,
    }
}
/**
 * @description Listing Questions 
 */
export const getAllQuestions =  async (searchObj, countObj) => {
    return instance.post('/search', {searchObj, countObj})
        .then(function (response) {
            return response.data.data
        })
        .catch(function (error) {
            return error
        })
}
/**
 * @description Filter facets
 */
const filterFacets = (results, facets) => {
    const finalFilterObj = {}
    facets.forEach((element) => {
            const valArr = []
            const metaValueArr = []
            results.map((result) => {  
                const val = result[element['@id']]
                const fVal = val && val['@id'] ? val['@id']: val  
                let keys= []
                if(fVal && Array.isArray(fVal)) {
                    fVal.map((val) => {
                        keys.push(filterMetaValue(val))
                        return true
                    })
                } else {
                    keys.push(filterMetaValue(fVal))
                }
                keys.map((key) => {
                    key = typeof key === 'object' ? key['@id'] ? filterMetaValue(key['@id']) : '' : key
                    if(key && metaValueArr[key]) {
                        metaValueArr[key] = metaValueArr[key] + 1
                    }   
                    else if(key) {
                        metaValueArr[key] = 1
                    }
                    return true
                })
                return result
            })
            element['rdfs:range']['meta:instance'].map((val, index) => {
                val = val['@id'] ? val['@id'] : val
                valArr.push({value: getReadFacetValue(filterMetaValue(val)), count: metaValueArr[filterMetaValue(val)] ? metaValueArr[filterMetaValue(val)] : 0})
                if(element['rdfs:range']['meta:instance'].length -1 === index) {
                    finalFilterObj[filterMetaValue(element['@id'])] =  [{type: 'value', data: valArr, field: filterMetaValue(element['@id'])}]
                }
                return val
            })
            

    });
    return finalFilterObj
}