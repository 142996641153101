import React, { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * @description layout component for layout
 */
class LayoutComponent extends Component {
  render () {
    return (
      <div className="layout -class">
        {this.props.children}
      </div>
    )
  }
}
/**
 *  LayoutComponent  prop types */
LayoutComponent.propTypes = {
  children: PropTypes.any
}
export default LayoutComponent
