import React from 'react'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import './App.css'
import Main from './app/containers/main'
import Login from './app/containers/login'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import history from './history'
import { isLoggedIn } from './app/utils/authentication'
import LayoutComponent from './app/layout'
/**
 * @desc  Redirect to main page if user already logged in, otherwise redirect to login page
 * */
// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isLoggedIn() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{
        pathname: '/login'
      }} />
    )
  )} />
)
/** @desc Redirect to login page if admin not logged in, otherwise redirect to dashboard page */
// eslint-disable-next-line react/prop-types
const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    !isLoggedIn() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{
        pathname: '/thought-leadership'
      }} />
    )
  )} />
)
export default function App () {
  // const signIn = false;
  return (
    <Router history={history}>
      <Switch>
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/" component={Login} />
        <LayoutComponent>
          <Switch>
            <PrivateRoute exact path="/thought-leadership" component={Main}/>
            <PrivateRoute exact path="/thought-leadership/:id/:name" component={Main}/>
            <PublicRoute exact path="/" component={Login} />
            <Route render={() => <Redirect to="/thought-leadership" />} />
          </Switch>
        </LayoutComponent>
      </Switch>

    </Router>
  )
};
