import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

class SnackbarComponent extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      open : true,
      message: '',
      type: ''
    }
  }
  openSnackBar = (message, type) => {
    this.setState({message, type, open: true})
  }
  handleClick = () => {
    this.setState({open: true})
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({open: false})
  };
  render () {
    const { open, message, type } = this.state
    return (
      <div>
          {type === 'success' && (
              <Snackbar open={open} autoHideDuration={5000} onClose={this.handleClose}>
                  <Alert onClose={this.handleClose} severity="success">
                      {message}
                  </Alert>
              </Snackbar>
         )}
         {type === 'error' && (
              <Snackbar open={open} autoHideDuration={5000} onClose={this.handleClose}>
                  <Alert onClose={this.handleClose} severity="error">
                      {message}
                  </Alert>
              </Snackbar>
         )}
         {type === 'warning' && (
              <Snackbar open={open} autoHideDuration={5000} onClose={this.handleClose}>
                  <Alert onClose={this.handleClose} severity="warning">
                      {message}
                  </Alert>
              </Snackbar>
         )}
      </div>
    )
  } 
}
export default SnackbarComponent