import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import { Link } from 'react-router-dom'
import {filterMetaValue} from '../../utils/helper'
import './sidebar-items.css'
import base64 from "base-64"

export const SecondaryListItems = (setsData, handleSetSearch, activeClass, setActiveClass, count) => {
  let allInfoCount = count

  return (
    <div className="menu-links-outer">

    <div className="seachQuestion">
        <input placeholder="Search for Info Need Sets" onChange={handleSetSearch}/>
    </div>
    <ListSubheader inset>INFO NEED SETS</ListSubheader>
    <Link to = "/thought-leadership" style={{ textDecoration: 'none', color: '#fff' }}  >
      <ListItem button onClick={() => { setActiveClass('/thought-leadership') }} className={activeClass === '/thought-leadership' ? 'active' : '' } >      
        <div className="itemName">
          <ListItemIcon><span className="icon-folder-alt"></span></ListItemIcon>
          <ListItemText primary="All Info Needs" />
        </div>
        <div className="itemValue">{allInfoCount}</div>  
      </ListItem>
    </Link>
    
    {setsData.map((set, index) => {
      let setCount = set['in:member'] ? set['in:member'].length : 0
      return (
        <Link to ={ "/thought-leadership/" + filterMetaValue(set['@id']) + '/' + base64.encode(set['dc:title'])} style={{ textDecoration: 'none', color: '#fff' }} key={index} >
          <ListItem button onClick={() => { setActiveClass('/thought-leadership/'+filterMetaValue(set['@id']) +'/' +  base64.encode(set['dc:title']) ) }} className={activeClass === '/thought-leadership/'+filterMetaValue(set['@id'])+'/' + base64.encode(set['dc:title']) ? 'active' : '' } >      
            <div className="itemName">
              <ListItemIcon><span className="icon-layers"></span></ListItemIcon>
              <ListItemText primary={set['dc:title'] ? set['dc:title'] : '' } />
            </div>
            <div className="itemValue">{setCount}</div> 
          </ListItem>
        </Link> 
      )
    })}
  </div>
  )
}
