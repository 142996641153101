
import React, { Component } from 'react'
import MainSearchTemplate from '../components/main/main-search'
import ConfirmationBox from '../components/UI/confirm-box'
import CONSTANTS from '../../constants'
import instance from "../config/axios-config"
import Spinner from '../components/spinner/spinner'
import {setMetaValue} from '../utils/helper'
import ErrorBoundary from '../components/error-boundary'
const {
    SAVE_NEEDS_TO_SET,MESSAGES, SUCCESS,
    ERROR, REMOVE_NEEDS_FROM_SET
} = CONSTANTS
/**
 * @description Search  container component 
 */
class SearchContainer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            selectedQuestions:  [],
            showConfirmationBox: false,
            loadData: false,
            loader: false,
            error: false,
            popupValue: '',
            needId: ''
        }
        this.loaderRef = React.createRef();
    }
    /**
     * @description When component will un-mount
     */
    componentWillUnmount() {
        this.setState({selectedQuestions: []})
    }
    /**
     * @description When component will receive new props
     * @param {*} nextProps 
     * @param {*} prevState 
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.isLoading) {
            return { selectedQuestions: [],  needId: '',  popupValue: ''}
        }
        if(nextProps.popupValue) {
            return { needId: nextProps.props.needId,  popupValue: nextProps.popupValue}
        }
        // Return null to indicate no change to state.
        return null;
    }
    /**
   * @description reset selection
   */
    resetSelection = () => {
        this.setState({selectedQuestions: []})
    }
    /** 
     * @description toggle selection
     */
    toggleSelection = (val) => {
        const selectedArr = this.state.selectedQuestions
        let index = selectedArr.indexOf(val)
        if(index !== -1) {
        selectedArr.splice(index, 1);
        this.setState({selectedQuestions: selectedArr})
        }else {
        selectedArr.push(val)
        this.setState({selectedQuestions: selectedArr})
        }    
    }
    /** 
     * @description Master toggle selection
     */
    masterToggle = (results) => {
        const arr = []
        if(this.isAllSelected(results)) {
        this.setState({selectedQuestions: []})
        } else {
        results.forEach(element => {
            arr.push(element['@id']) 
        });
        this.setState({selectedQuestions: arr})
        }
    }

    /**
     * @description Check for is selected
     */
    isAllSelected = (results) => {
        return results.length === this.state.selectedQuestions.length
    }
    /**
    * @description Handle popup
    */
    handlePopup = (value) => {
        this.setState({popupValue: value, needId: ''})
    }
    /**
     * @description save needs to set
     */
    saveNeedsToSet = (needs, set) => {
        this.loaderRef.current && this.loaderRef.current.showLoader()
        let sNeeds = this.state.selectedQuestions.length > 0 ? this.state.selectedQuestions : needs
        this.props.resetSearch()
        this.setState({loader: true,  popupValue: ''})
        SAVE_NEEDS_TO_SET['infoNeeds'] = sNeeds
        SAVE_NEEDS_TO_SET['@id'] = set
        instance.post('/add-needs-to-set', SAVE_NEEDS_TO_SET)
        .then((res) => {
            this.setState({loader: false, error: false})
            this.props.snackRef.current.openSnackBar(MESSAGES.QUESTION_ADDED_TO_SET, SUCCESS)
        
        })
        .catch((err) => {
            this.setState({loader: false, loadData: true})
            this.props.snackRef.current.openSnackBar(MESSAGES.SOMETHING_WENT_WRONG, ERROR)
        })
        .finally(() => {
            // To update parent main container for initiate the search again
            this.loaderRef.current && this.loaderRef.current.hideLoader()
            this.props.resetSearch()
            
        })
    }
    /**
   * @description Handle bulk delete confirmation box
   */
    handleBulkDeleteBox = () => {
        this.setState({showConfirmationBox: !this.state.showConfirmationBox})
    }
    
    /**
   * @description  Handle bulk delete 
   */
    handleBulkDelete = () => {
        this.loaderRef.current && this.loaderRef.current.showLoader()
        this.setState({loader: true, showConfirmationBox: false})
        const { selectedQuestions} = this.state
        const setId = this.props.setId ? this.props.setId : ''
        // To update parent main container
        this.props.resetSearch()
        if(setId) {
            REMOVE_NEEDS_FROM_SET['@id'] = setMetaValue(setId)
            REMOVE_NEEDS_FROM_SET['infoNeeds'] = selectedQuestions
            instance.delete('/delete-needs-from-set',  {data: REMOVE_NEEDS_FROM_SET})
            .then((res) => {
                this.setState({loader: false, error: false, loadData: true})
                this.props.snackRef.current.openSnackBar(MESSAGES.QUESTION_DELETED_FROM_SET, SUCCESS)
            })
            .catch((err) => {
                this.setState({loader: false, loadData: true})
                this.props.snackRef.current.openSnackBar(MESSAGES.SOMETHING_WENT_WRONG, ERROR)
            })
            .finally(() => {
                // To update parent main container for initiate the search again
                this.props.resetSearch()
                this.loaderRef.current && this.loaderRef.current.hideLoader()
            })
        } else {
            const needs = selectedQuestions.map((q) => {
            return {"@id": q}
            })
            instance.delete('/delete-needs', {data: needs})
            .then((res) => {
                this.setState({loader: false, error: false, loadData: true})
                this.props.snackRef.current.openSnackBar(MESSAGES.QUESTION_DELETED_ALL, SUCCESS)
            })
            .catch((err) => {
                this.setState({loader: false, loadData: true})
                this.props.snackRef.current.openSnackBar(MESSAGES.SOMETHING_WENT_WRONG, ERROR)
            })
            .finally(() => {
                // To update parent main container for initiate the search again
                this.props.resetSearch()
                this.loaderRef.current && this.loaderRef.current.hideLoader()
            })
        }
    }

    render () {
        const { error} = this.state 
        return (
            <>
            <ErrorBoundary>
                <Spinner ref={this.loaderRef}/>
                <MainSearchTemplate
                    {...this.props}
                    {...this.state}
                    selectedQuestions={this.state.selectedQuestions}
                    toggle={this.toggleSelection}
                    masterToggle={this.masterToggle}
                    resetSelection={this.resetSelection}
                    handleBulkDeleteBox={this.handleBulkDeleteBox}
                    handlePopup={this.handlePopup}
                    saveNeedsToSet={this.saveNeedsToSet}
                />
            </ErrorBoundary>
            
            {this.state.showConfirmationBox && 
            (<ConfirmationBox deleteInfoSet={this.handleBulkDelete}
            handleBox={this.handleBulkDeleteBox}
            title={CONSTANTS.CONFIRM_BOX_DELETE_QUESTION_TITLE}
            message={CONSTANTS.CONFIRM_BOX_DELETE_QUESTION_MESSAGE}
            />)}
            {/* {loader && (<Spinner/>)} */}
            {error && (<div></div>)}
            </> 

        )
    }
}
export default SearchContainer
