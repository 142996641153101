
import React, { Component } from 'react'
import AddInfoNeedTemplate from '../components/question/add-need'
import instance from "../config/axios-config"
import CONSTANTS from '../../constants'
import { filterMetaValue, getSubValue, getReadFacetValue} from '../utils/helper'
import base64 from "base-64"
import history from '../../history'
import ConfirmationBox from '../components/UI/confirm-box'
const {
    ERROR, MESSAGES, META_DATA_OBJ, SUCCESS, META_DATA_RANGE_VALUES
    } = CONSTANTS
/**
 * @description Add/edit  info need container component 
 */
class InfoNeed extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loader: false,
            metaDataList: [],
            metaPopup: '',
            metaPopupList: [],
            allowSubmit: false,
            needData: {},
            showConfirmationBox: false
        }
     //   this.outsideClickRef = React.createRef();
    }
    componentDidMount = () => {
        // if(this.props.type === 'add') {
            //document.addEventListener('mousedown', this.handleClickOutside);
            this.setState({loader: true, error: false, needData: {}})
            this.props.loaderRef.current && this.props.loaderRef.current.showLoader()
            instance.post('/metadata', {searchObj: META_DATA_OBJ, rangeValues: META_DATA_RANGE_VALUES })
            .then((res) => {
                this.props.loaderRef.current && this.props.loaderRef.current.hideLoader()
                const metaData = res.data.data ? res.data.data : {}
                const metaItems = metaData
                const finalMetaItems = metaItems.map((item) => {
                    const val = item['meta:has_default'] && item['meta:has_default']['@id'] ? filterMetaValue(item['meta:has_default']['@id']) : ''
                    let obj =   {
                        field: getReadFacetValue(filterMetaValue(item['@id'])),
                        subVal: getSubValue(item['@id']),
                        default: val,
                        values: val ? [val]: [],
                        metaList: item['rdfs:range'] && item['rdfs:range']['meta:instance'] ? item['rdfs:range']['meta:instance'] : [],
                        required: val ? true : false,
                        id: item['@id'],
                        multiValued: item['meta:multivalued'] ? item['meta:multivalued'] : "false",
                        labelCode: item['rdfs:range'] && item['rdfs:range']['meta:instance'] ? getSubValue(item['rdfs:range']['meta:instance'][0]['@id']) : 'in',
                        type: item['rdfs:range'] && item['rdfs:range']['meta:instance'] ? 'list' : item['rdfs:range'] && item['rdfs:range']['@id'] ? filterMetaValue(item['rdfs:range']['@id']) : ''
                    }
                    return obj
                }) 
                if(this.props.needId) {
                    this.getInfoNeed(this.props.needId, finalMetaItems)
                }
                this.setState({loader: false, error: false, metaDataList: finalMetaItems, allowSubmit: true})
            })
            .catch((err) => {
                this.props.loaderRef.current && this.props.loaderRef.current.hideLoader()
                this.setState({loader: false, error: true })
                this.props.snackbarRef.current.openSnackBar(MESSAGES.SOMETHING_WENT_WRONG, ERROR)
            })
            .finally(() => {})
            
        // }
        
    }
    /**
     * @description Alert if clicked on outside of element
     */
    handleClickOutside = (event) => {
        if (this.outsideClickRef && !this.outsideClickRef.current.contains(event.target)) {
            // alert('You clicked outside of me!');
        }
    }
    /**
     * @description Delete confirmation 
     */
    deleteConfirmationBox = () => {
        this.setState(({showConfirmationBox: !this.state.showConfirmationBox}))
    }
    /**
     * @description Delete need by id
     * @param {*} infoNeedId 
     */
    deleteNeed = () => {
        let needId = this.props.needId
        this.setState({loader: true, showConfirmationBox: false})
        this.props.loaderRef.current && this.props.loaderRef.current.showLoader()
        instance.delete('/delete-needs', {data: [{"@id":needId}]})
        .then((res) => {
            this.setState({loader: false})
            //history.push('/thought-leadership')
            const setName = this.props.setName ? base64.encode(this.props.setName) : ''
            this.props.snackbarRef.current.openSnackBar(MESSAGES.QUESTION_DELETED, SUCCESS)
            this.props.handlePopup('')
            let url = "/thought-leadership"
            if(this.props.setId) {
                url = url + '/' + this.props.setId
            }
            if(setName) {
                url = url + '/' + setName
            }
            this.props.handleHeaderPopup('')
            history.push(url)
        
        })
        .catch((err) => {
            
            this.setState({loader: false})
            this.props.snackbarRef.current.openSnackBar(MESSAGES.SOMETHING_WENT_WRONG, ERROR)
        })
        .finally(() => { this.props.loaderRef.current && this.props.loaderRef.current.hideLoader()})
    }
    /**
     * @description Get info need details
     * @param {*} value 
     * @param {*} meta 
     */
    getInfoNeed = (infoNeedId, mataListTemp) => {  
        this.setState({loader: true, error: false, needData: {}})
        this.props.loaderRef.current && this.props.loaderRef.current.showLoader()
        instance.get('/info-need', {params: {needId: infoNeedId}})
        .then((res) => {
            const needData = res.data.data ? res.data.data : {}
            const metaDataList = this.state.metaDataList
            const filterMetaList = metaDataList.map((metaData) => {
                let values = []
                let valExists = needData[metaData.id] 
                if(valExists && valExists['@id']) {
                    values.push(filterMetaValue(valExists['@id']))
                    metaData.values = values
                } else if (valExists && (typeof valExists === 'string' || typeof valExists === 'boolean')) {
                    values.push(valExists)
                    metaData.values = values
                } else if (valExists && valExists[0] && typeof valExists[0] === 'string') {
                    valExists.map((val) => {
                        values.push(val)
                        return true
                    })
                    metaData.values = values
                } else if (valExists && valExists[0] && typeof valExists[0] === 'object') {
                    valExists.map((val) => {
                        values.push(filterMetaValue(val['@id']))
                        return true
                    })
                    metaData.values = values
                }
                return metaData
            })
            this.setState({needData, metaDataList: filterMetaList, allowSubmit : true })
        })
        .catch((err) => {
            this.setState({loader: false, error: true, needData: {}})
            this.props.snackbarRef.current.openSnackBar(MESSAGES.SOMETHING_WENT_WRONG, ERROR)
        })
        .finally(() => { this.props.loaderRef.current && this.props.loaderRef.current.hideLoader()})
    }
    /**
     * @description Update meta value
     */
    updateMetaValue = (value, metaId) => {
        let saveButton = true
        const metaList = this.state.metaDataList.map((data) => {
            if(data.id === metaId) {
                const index = data.values.indexOf(value);
                if (index > -1) {
                    data.values.splice(index, 1);
                }
            }
            if(data.default && data.values.length === 0) {
                saveButton = false
            }
            return data
        })
        this.setState({metaDataList: metaList, allowSubmit: saveButton})
    }
    /**
     * @description Right side update meta vale
     * @param {*} value 
     */
    rightPopMetaValue = (metaValue, metaField, allowMulti) => {
        let saveButton = true
        let list = this.state.metaDataList
        list.map((item) => {
            if(item.id === metaField) {
                if(allowMulti === 'true') { 
                    if(!item.values.includes(metaValue)) {
                        item.values.push(metaValue)
                    }
                    
                } else {
                    item.values = [metaValue]
                }
            }
            if(item.default && item.values.length === 0) {
                    saveButton = false
            }
            return item
        })
        this.setState(({metaDataList: list,  allowSubmit: saveButton}))
    }
    /**
     * @description Set meta popup value
     */
    setMetaPopVal = (value) => {

        this.setState({metaPopup: value})
    }
    render () {
        const {saveQuestion, handlePopup, needId, toggle, handleHeaderPopup} = this.props
        const {metaDataList, metaPopup, metaPopupList, allowSubmit, needData} = this.state
        return (
            <>  
            {/* <div ref={this.outsideClickRef}> */}
            <AddInfoNeedTemplate 
                handlePopup={handlePopup} 
                saveQuestion={saveQuestion}
                metaDataList={metaDataList}
                allowSubmit={allowSubmit}
                handleHeaderPopup={handleHeaderPopup}
                metaPopup={metaPopup}
                deleteNeed={this.deleteConfirmationBox}
                type={needId ? 'edit' : 'add'}
                needId={needId}
                toggle={toggle}
                needData={needData}
                metaPopList = {metaPopupList}
                setMetaPopVal = {this.setMetaPopVal}
                updateMetaValue={this.updateMetaValue}
                rightPopMetaValue={this.rightPopMetaValue}
            />
            {/* </div> */}
            {this.state.showConfirmationBox && 
            (<ConfirmationBox deleteInfoSet={this.deleteNeed}
            handleBox={this.deleteConfirmationBox}
            title={CONSTANTS.CONFIRM_BOX_DELETE_QUESTION_TITLE}
            message={CONSTANTS.CONFIRM_BOX_DELETE_QUESTION_MESSAGE}
            />)}
    </>

        )
    }
}
export default InfoNeed
