import React from 'react'
import './spinner.css'
/**
 * @description Spinner component
 */
class Spinner extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      show : false,
    }
  }
  showLoader = () => {
    this.setState({show: true})
  }
  hideLoader = () => {
    this.setState({show: false})
  };
  render () {
    const { show } = this.state
    return (
      <div>
            { show && (
              <div className="showbox" id = "loader" >
              <div className="loader">
                <svg className="circular" viewBox="25 25 50 50">
                  <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
                </svg>
              </div>
            </div>  
            )}
      </div>
    )
  } 
}
export default Spinner