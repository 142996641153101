
import history from '../../history'
/**
 * @description If already logged in
*/
export const isLoggedIn = () => {
  const token = localStorage.getItem('SP_AUTH_TOKEN')
  if (token) { return true }
  return false
}
/**
  * @desc This will check that email have correct pattern or not
  * @param {string} emailValue
*/
export const correctEmail = (emailValue) => {
  if (emailValue && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailValue)) {
    return false
  }
  return true
}

/**
 * @desc After successfull login api response
 * @param {string} token
*/
export const onLogin = (data) => {
  /** Set token in local storage */
  localStorage.setItem('SP_AUTH_TOKEN', data.token ? data.token : '')
  if (data.user) {
    // TODO for temporary default user name
    const firstName = data.user.first_name ? data.user.first_name : ''
    const lastName = data.user.last_name ? data.user.last_name : ''
    localStorage.setItem('SP_USER', firstName + ' ' + lastName)
  }
  history.push('/thought-leadership')
  return true
}
/** @desc After successfully logout */
export const onLogout = () => {
  /** Remove token from local storage */
  localStorage.removeItem('SP_AUTH_TOKEN')
  localStorage.removeItem('SP_USER')
  history.push('/login')
  return true
}
