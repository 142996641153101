import React from 'react'
import CONSTANTS from '../../constants'
/**
 * @description Error boundary component 
 */
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>{CONSTANTS.MESSAGES.SOMETHING_WENT_WRONG}</h1>;
        }
    
        return this.props.children; 
    }
}
export default ErrorBoundary