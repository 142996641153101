import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
/**
 * @description AddNeedsToSetTemplate  component
 */
export default function AddNeedsToSetTemplate(data) {
    const {sidebarList, handlePopup, handleSetSearch, saveNeedsToSet, handleHeaderPopup, getInfoNeed, needId, selectedNeeds} = data
    const [setValue, updateSetValue] = React.useState('')
    return (
        <>
            <div className="common-modal-outer">
                <div className="modal-Heading">
                    ADD TO A INFO NEED SET
                </div>
                <div className="modal-bodyContent">
                    <div className="seachQuestionSet">
                        <input placeholder="Search Info Need Sets" onChange={handleSetSearch}/>
                    </div>
                    <div className="radio_items">
                        <div className="innerRadio_box">
                                <RadioGroup>
                                {sidebarList.map((set, index) => {
                                return (
                                <FormControlLabel onChange={(event)=> {
                                    updateSetValue(event.target.value)
                                    }} checked={setValue === set['@id']} value={set['@id']} control={<Radio />} label={set['dc:title']} key={index} />
                                )
                                })}
                                </RadioGroup>
                        </div>
                    </div>
                </div>
                
                <div className="modal-footer">
                <button className="cancel_btn" onClick={() => {
                    if(needId){
                        handleHeaderPopup('')
                        getInfoNeed(needId)
                        
                    } else {
                        handlePopup('');
                    }
                    
                    }}>CANCEL</button>
                    <button className="save_btn" onClick={(event) => {saveNeedsToSet(selectedNeeds, setValue)}}><span className="icon-check"></span>SAVE</button>
                </div>
            </div>
        </>
    )
}
