import React, { Component } from 'react'
// import ReactHtmlParser from 'react-html-parser'
import './result.css'
import PropTypes from 'prop-types'
import  {filterMetaValue,getReadFacetValue} from '../../utils/helper'

/**
 * @description Result card component to display results
 */
class ResultCard extends Component {
 
  render () {

    const { key, result } = this.props.props
    const {toggle, selectedQuestions, getInfoNeed}= this.props
    const resultId = result['@id']
    const qText = result['core:text'] ? result['core:text'] : ''
    const importance = result['in:importance'] ? result['in:importance']  : {};
    const industries = result['in:industry'] ? result['in:industry'] : [];
    const relevant_for = result['in:relevant_for'] ? result['in:relevant_for']  : [];
    const relevant_for_pa = result['in:relevant_for_practicearea'] ? result['in:relevant_for_practicearea']  : [];
    const information_status = result['core:information_status'] ? result['core:information_status']  : {};
    const has_answer_status = result['in:has_answer_status'] ? result['in:has_answer_status']  : {};
    const geographicArea = result['schema:geographicArea'] ? result['schema:geographicArea']  : [];
    const relevant_for_natural_hazard = result['in:relevant_for_natural_hazard'] ? result['in:relevant_for_natural_hazard']  : [];
    const relevant_for_tech_hazard = result['in:relevant_for_tech_hazard'] ? result['in:relevant_for_tech_hazard']  : [];
    //const keywords = result['core:keywords'] ? result['core:keywords']  : [];
    const author = result['schema:author'] ? result['schema:author']  : {};
    //const entityRef = result['in:entityRef'] ? result['in:entityRef']  : {};
    
    return (
      <>
        <div key={key} className={"outerCard"}>
          {/* {selectedQuestions.length > 1 && ( */}
            <div className={selectedQuestions.indexOf(resultId) !== -1 ? "selectionRadio" : "selectionRadio selectionRadioBefore hoverClass"} onClick={(e) => {

              e.preventDefault(); 
              toggle(resultId)
              }}>
            <span className="fa fa-circle-thin"></span>
            {selectedQuestions.indexOf(resultId) !== -1 && (
              <span className="fa fa-check-circle selectedItem"></span>
            )}
        </div>
          {/* )} */}
          
          <div className="listing-box" onClick={(e) => {e.preventDefault();
              getInfoNeed(resultId)
          }}>
            <div className="listing-box-header"> 
              <div className="listing-box-title">
                <div className="listing-box-heading">
                  <h2>{qText}</h2>
                  <div className="listing-box-more">
                  </div>
                </div>
              </div>
            </div>
            <div className="listing-box-tags">
              {
                importance && importance['@id']  && (  
                  <div className="tags-list">
                    <span className="icon-tag tag-list-icon"></span>
                    <div className="tags-listing">
                      {filterMetaValue(importance['@id'] )}
                    </div>
                  </div>
                )
              }
              {
                industries.length > 0 && (
                    industries.map((item, index) => {
                        const val = getReadFacetValue(filterMetaValue(item['@id'] ? item['@id'] : ''))
                        return (
                          <div className="tags-list" key={index}>
                            <span className="icon-tag tag-list-icon"></span>
                            <div className="tags-listing">
                              {val}
                            </div>
                          </div>
                        )
                    })
                )
              }
                  
              {
                relevant_for.length > 0 && (
                  relevant_for.map((item, index) => {
                      const val = getReadFacetValue(filterMetaValue(item['@id'] ? item['@id'] : ''))
                      return (
                        <div className="tags-list" key={index}>
                          <span className="icon-tag tag-list-icon"></span>
                          <div className="tags-listing">
                            {val}
                          </div>
                        </div>
                      )
                  })
                )
              }
              {
                relevant_for_pa.length > 0 && (
                  relevant_for_pa.map((item, index) => {
                      const val = getReadFacetValue(filterMetaValue(item['@id'] ? item['@id'] : ''))
                      return (
                        <div className="tags-list" key={index}>
                          <span className="icon-tag tag-list-icon"></span>
                          <div className="tags-listing">
                            {val}
                          </div>
                        </div>
                      )
                  })
                )
              }
              {
                information_status && information_status['@id']  && (  
                  <div className="tags-list">
                    <span className="icon-tag tag-list-icon"></span>
                    <div className="tags-listing">
                      {filterMetaValue(information_status['@id'] )}
                    </div>
                  </div>
                )
              }
              {
                has_answer_status && has_answer_status['@id']  && (  
                  <div className="tags-list">
                    <span className="icon-tag tag-list-icon"></span>
                    <div className="tags-listing">
                      {filterMetaValue(has_answer_status['@id'] )}
                    </div>
                  </div>
                )
              }
              {
                geographicArea.length > 0 && (
                  geographicArea.map((item, index) => {
                      const val = getReadFacetValue(filterMetaValue(item['@id'] ? item['@id'] : ''))
                      return (
                        <div className="tags-list" key={index}>
                          <span className="icon-tag tag-list-icon"></span>
                          <div className="tags-listing">
                            {val}
                          </div>
                        </div>
                      )
                  })
                )
              }
              {
                relevant_for_natural_hazard.length > 0 && (
                  relevant_for_natural_hazard.map((item, index) => {
                      const val = getReadFacetValue(filterMetaValue(item['@id'] ? item['@id'] : ''))
                      return (
                        <div className="tags-list" key={index}>
                          <span className="icon-tag tag-list-icon"></span>
                          <div className="tags-listing">
                            {val}
                          </div>
                        </div>
                      )
                  })
                )
              }
              {
                relevant_for_tech_hazard.length > 0 && (
                  relevant_for_tech_hazard.map((item, index) => {
                      const val = getReadFacetValue(filterMetaValue(item['@id'] ? item['@id'] : ''))
                      return (
                        <div className="tags-list" key={index}>
                          <span className="icon-tag tag-list-icon"></span>
                          <div className="tags-listing">
                            {val}
                          </div>
                        </div>
                      )
                  })
                )
              }
              {
                author && author['@id'] && (  
                  <div className="tags-list ggg">
                    <span className="icon-tag tag-list-icon"></span>
                    <div className="tags-listing">
                      {filterMetaValue(author['@id'] )}
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}
/** ResultCard component prop types */
ResultCard.propTypes = {
  props: PropTypes.object

}

export default ResultCard
